import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/Home.vue'),
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/libraryServices',
    name: 'LibraryServices',
    component: () => import('../views/libraryServices/LibraryServices.vue'),
    meta: {
      title: 'Library Services'
    }
  },
  {
    path: '/databases',
    name: 'Databases',
    component: () => import('../views/databases/Databases.vue'),
    meta: {
      title: 'Databases'
    }
  },
  {
    path: '/libraryBoard',
    name: 'LibraryBoard',
    component: () => import('../views/libraryBoard/LibraryBoard.vue'),
    meta: {
      title: 'Library Board'
    }
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('../views/history/History.vue'),
    meta: {
      title: 'History'
    }
  },
  {
    path: '/friends',
    name: 'Friends',
    component: () => import('../views/friends/Friends.vue'),
    meta: {
      title: 'Friends'
    }
  },
  {
    path: '/localFacilities',
    name: 'LocalFacilities',
    component: () => import('../views/localFacilities/LocalFacilities.vue'),
    meta: {
      title: 'Local Facilities'
    }
  },
  {
    path: '/localServices',
    name: 'LocalServices',
    component: () => import('../views/localServices/LocalServices.vue'),
    meta: {
      title: 'Local Services'
    }
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: () => import('../views/contactUs/ContactUs.vue'),
    meta: {
      title: 'Contact Us'
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('../views/calendar/Calendar.vue'),
    meta: {
      title: 'Calendar'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/404/PageNotFound.vue'),
    meta: {
      title: 'Page Not Found'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
