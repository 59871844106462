<template>
  <Header/>
  <router-view></router-view>
  <Footer/>
</template>

<script>
import Header from "./components/Header"
import Footer from "./components/Footer"

export default {
  name: "app.vue",
  components: { Header, Footer },
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title + ' | Rauchholz Memorial Library'
    }
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

</style>
